.transfer{
    background: #fff;
    padding: 6em 0;
    .desc{
        padding: 0 2em;
        p{
            margin:0.5em 0;
        }
        .title1{
            font-size: 1.2em;
            color: #4E5AEE;
        }
        .title2{
            font-size: 2.3em;
            line-height: 1.5em;
        }
        .title3{
        
            font-size: 1.2em;
            line-height: 2em;
            margin: 1em 0;
        }
    }
    .channels{
        width: 100vw;
        display: flex;
        justify-content: space-between;
       
        .channel{
            width: 33%;
            &:nth-child(1){
                margin-top: 5em;       
            }
            &:nth-child(2){
                margin-top: 2.5em;       
            }
            img{
                display: block;
                width: 100%;
            }
          
        }
    }
}