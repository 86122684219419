.channel{
    width: 100vw;
    background: #F7FAFC;
    padding: 5em 0;

    .desc{
        padding:0 2em;
        .title1{
            font-size: 1.2em;
            color: #4E5AEE;
            margin: 1.5em 0;
        }
        .title2{
            font-size: 2.3em;
            line-height: 1.5em;
        }
        .title3{
            font-size: 1.1em;
            line-height: 2em;
            margin: 1em 0;
        }
    }
    
   .channelPic{
    width: 100%;
    display: block;
    margin: 0 auto;
    padding: 3em 0;
   }
}