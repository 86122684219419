.transfer{
    width: 1100px;
    margin: 0 auto;
    background: #fff;
    padding: 80px 0;
    height: 800px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .desc{
        width: 40%;
        height: 100%;
        display: flex;
       justify-content: center;
        flex-direction: column;
        p{
            font-weight:  PingFang SC;
            margin: 15px 0;
        }
        .title1{
            font-size: 14px;
            color: #4E5AEE;
        }
        .title2{
            font-size: 37px;
           margin: 10px 0;
        }
        .title3{
            width: 75%;
            font-size: 14px;
            font-weight: 300;
            line-height: 24px;
        }
    }
    .channels{
        width: 55%;
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        .channel2{
           position: relative;
           top: -50px;
        }
        .channel3{
            position: relative;
            top: -100px;
         }
         
    }   
}