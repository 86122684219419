.contact-w {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .4);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-con {
    width: 500px;
    height: 560px;
    background: #fff;
    border-radius: 15px;
    border: 1.5px solid rgba(255, 255, 255, 0.56);
    box-sizing: border-box;
    backdrop-filter: blur(7px);
    position: relative;

}

.close-btn {
    position: absolute;
    font-size: 30px;
    top: 10px;
    right: 20px;
    cursor: pointer;
}

.title {
    font-size: 20px;
    text-align: center;
    margin: 26px 0;
}

.form-w {
    width: 380px;
    margin: 0 auto;
    margin-top: 40px;
}

.label {
    font-size: 14px;
    margin: 16px 0px;
}

.input-w {
    width: 100%;
    height: 50px;
    margin-bottom: 25px;

    input {
        width: 100%;
        height: 50px;
        border-radius: 4px;
        border: 0;
        outline: none;
        background: #F5F5F5;
        text-indent: 24px;
        font-size: 14px;

        &::placeholder {
            color: #999;
        }
    }

    textarea {
        width: 100%;
        height: 106px;
        resize: none;
        font-size: 14px;
        border: 0;
        outline: none;
        background: #F5F5F5;
        padding: 10px 15px;

        &::placeholder {
            color: #999;
        }
    }
}

.submit-btn {
    margin-top: 80px;

    button {
        width: 100%;
        height: 50px;
        background: #4E5AEE;
        outline: none;
        border: 0;
        color: #fff;
        border-radius: 6px;
        font-size: 16px;
        cursor: pointer;

        &:hover {
            opacity: .95;
        }
    }
}