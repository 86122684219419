.choice_wrap{
    background:#F7FAFC ;
    .choice{
        width: 1100px;
        margin: 0 auto;
        height: 700px;
        padding: 80px 0;
        align-items: center;
        display: flex;
        .desc{
            width: 30%;
            .title1{
                font-size: 14px;
                color: #4E5AEE;
            }
            .title2{
                font-size:37px;
                margin: 14px 0;
            }
            .title3{
                font-size:14px;
                font-weight: 300;
                margin-bottom: 40px;
                line-height: 24px;
            }
            .btn_group{
                .btn1{
                    color: #fff;
                    background: #4E5AEE;
                    border-radius: 6px;
                    margin-right: 20px;
                }
                .btn2{
                    color: #4E5AEE;
                }
            }
        }
       .right{
           width: 70%;
           height: 100%;
           position: relative;
           .map{
               width: 100%;
               position: absolute;
               left: 0;
           }
           .indian{
            position: absolute;
            right: 15%;
            top: 22%;
           }
       }
    }
}