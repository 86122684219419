.choice{
    background: #F7FAFC;
    padding: 6em 0;
    .desc{
        padding: 0 2em;
        p{
            margin:0.5em 0;
        }
        .title1{
            font-size: 1.2em;
            color: #4E5AEE;
        }
        .title2{
            font-size: 2.3em;
            line-height: 1.5em;
        }
        .title3{
        
            font-size: 1.2em;
            line-height: 2em;
            margin: 1em 0;
        }
    }
    .map_wrap{
        width: 100%;
        margin-top: 4em;
        position: relative;
        .map{
            width: 100%;
        }
        .indian{
            width: 40%;
            position: absolute;
            top: 20%;
            left: 48%;
        }
    }
}