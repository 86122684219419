#root .dots {
    li {
        background: #999;
        width: 20px;
    }

    .slick-active button {
        background: #4E5AEE;
    }
}

html body {
    overflow-y: scroll;
}

#content {
    width: 1200px;
    margin: 0 auto;
}



.anchor {
    display: none;
}

.wmde-markdown {
    h1 {
        font-size: 24px;
    }

    h2 {
        font-size: 18px !important;
    }

    h3 {
        font-size: 16px;
    }

    p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;
    }

    li {
        font-size: 14px;
    }
}

img {
    /* Firefox */
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    -ms-interpolation-mode: nearest-neighbor;
}