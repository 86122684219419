.shop{
    padding: 6em 0;

    .titles{
        padding: 0 2em;
        p{
            margin:0.5em 0;
        }
        .title1{
            font-size: 1.2em;
            color: #4E5AEE;
        }
        .title2{
            font-size: 2.3em;
            line-height: 1.5em;
        }
        .title3{
            font-size: 1.2em;
            line-height: 2em;
            margin: 1em 0;
        }
    }
    .modules{
        padding: 0 2em;
        margin-top: 3em;
        text-indent: 3em;
        .includes{
            position: relative;
            font-size: 1.2em;
            font-weight: 550;
            margin-bottom: 2em;
            &::before{
                content: '';
                display: inline-block;
                background:#4E5AEE ;
                width: 0.2em;
                height: 2em;
                position: absolute;
                left: 0;
                top: -0.4em;
            }
        }
        ul{
            display: flex;
            flex-direction: column;
       
            li{
                font-size: 1.1em;
                height: 4em;
                display: flex;
                align-items: center;
                &::before{
                    content: '';
                    display: flex;
                    width: 1.5em;
                    background: rgba(78, 90, 238, 0.2);
                    height: 1.5em;
                    background-image: url('../../assets/icon/Vector.png'); 
                    background-repeat:no-repeat;
                    background-position: center;
                    border-radius: 50%;
                    margin:0 1em 0 3em;
                }
            }
        }

    }
    .pics{
       
        img{
            width: 90%;
            margin: auto;
            display: block;
        }
    }
}