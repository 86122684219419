$min-width: 1100px;

$hover-color: #4E5AEE;
$active-color: #4E5AEE;
$primary-color: #4E5AEE;
$primary-hover-color: #4E5AEE;
$normal-font-color: #222222;
$primary-font-color: #ffffff;

$normal-font-size: 14px;

$content-width: 1100px;

$content-padding: 0 36px;

$h5-footer-height: 0.48rem;