.header {
    width: 100vw;
    background-image: url(../../assets/image/h5bg.png);
    background-repeat: no-repeat;
    background-size: 100vw 70vh;
    position: relative;

    //  min-height: 100vh;
    .navbar {
        display: flex;
        width: 100%;
        padding: 1.8em;
        justify-content: space-between;
        margin-bottom: 1em;

        .menuBtn {
            width: 4em;
            height: 2.5em;
        }
    }

    .desc {
        padding: 3em 2em;
        margin: 2em 0;

        img {
            height: 3em;
        }

        .subtitle {
            font-size: 2.5em;
            font-weight: 545;
            margin: 1.4em 0;
        }

        p {
            font-size: 1.2em;
            line-height: 2.5em;
        }

        i {
            color: #4E5AEE;
            font-size: 1.1em;
        }
    }

    .btn_group {
        margin-top: 2em;

        .trial {
            background: #4E5AEE;
            color: #fff;
            border-radius: 6px;
        }

        .contack {
            color: #4E5AEE;
        }
    }
}

.popover {
    position: absolute;
    z-index: 666;
    top: 1em;
    left: 50%;
    transform: translateX(-50%);
    width: 85%;
    height: 15em;
    background: #fff;
    box-shadow: 0px 10px 20px rgba(23, 55, 97, 0.1);
    border-radius: 4px;
    padding: 1em 3em;
    box-sizing: border-box;

    .close-btn {
        text-align: right;
        font-size: 2em;
    }

    h3 {
        font-size: 1.2em;
        font-weight: 550;
    }
}

.submenu {
    width: 100%;
    height: 8em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .submenu-item {
        width: 48%;
        display: flex;
        align-items: center;
    }

    .submenu-imgw {
        margin-right: 1em;
    }
}

.docs {
    button {
        width: 100%;
        height: 2.5em;
        border: 0;
        outline: 0;
        color: #333;
        background: #F7FAFC;
    }
}