.btn_group {
    width: 220px;
    margin: 50px 0;
    display: flex;
    justify-content: space-between;

    button {
        font-size: 14px;
    }

    .toBegin {
        background: #4E5AEE;
        color: #fff;
        height: 36px;
        border-radius: 6px;
    }

    .contact {
        color: #4E5AEE;
        font-size: 14px;
    }
}