@import "../../assets/css/common.scss";

.document {
  width: 100%;
  min-width: $content-width;
  background: #fff;
  padding-bottom: 80px;
  background: #F7FAFC;

  .document-view {
    width: $content-width;
    margin: auto;
    padding-top: 60px;

    .document-search {
      width: $content-width;
      height: 80px;
      background: #FFFFFF;
      position: relative;
      top: -40px;
      display: flex;
      justify-content: center;
      align-items: center;

      .seach {
        width: 1135px;
        height: 44px;
      }
    }

    .document-layout {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      position: relative;

      .document-nav {
        position: absolute;
        width: 200px;
        height: 100%;
        right: -200px;

        .md-nav {
          position: sticky;
          left: 0;
          top: 0;
          background: #fff;
          margin-left: 20px;
        }
      }
    }

    .document-sider {
      width: 270px;
      background: #FFFFFF;
      padding: 0 0 32px 36px;
      box-sizing: border-box;
      position: sticky;
      left: 0;
      top: 0;

      // position: absolute;
      // left: 0;
      // top: 0;
      .route-title {
        font-size: 20px;
        font-weight: 600;
        color: #222222;
        line-height: 28px;
        margin-bottom: 12px;
        padding-top: 32px;
        cursor: pointer;
      }

      .route-con {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
        margin-bottom: 12px;
        cursor: pointer;
      }

      .selected {
        color: #4E5AEE;
      }

      .route-con:hover {
        color: #4E5AEE;
      }
    }

    .scene-ab {
      position: fixed;
      left: 50%;
      top: 80px;
      margin-left: -594px;
    }

    .document-content {
      width: 834px;
      min-height: 500px;
      background: #FFFFFF;
      padding: 32px 36px 60px;
      box-sizing: border-box;
      margin-left: 29px;

      h1 {
        font-size: 24px;
        font-weight: 600;
        color: #222222;
        line-height: 42px;
        margin-top: 30px;
        margin-bottom: 0;

      }

      h2 {
        font-size: 18px;
        font-weight: 600;
        color: #222222;
        line-height: 25px;
        margin-top: 24px;
        margin-bottom: 0;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
        margin-top: 12px;
        margin-bottom: 0;
      }

      img {
        width: 100%;
      }
    }

    .ant-menu-sub.ant-menu-inline {
      background: #FFFFFF;
    }
  }
}