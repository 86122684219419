.channels_wrap{
   width: 100%;
   background: #F7FAFC;

   .channels{
       width: 1100px;
       margin: auto;
       padding: 100px 0;
       h2,p{
           font-size: 14px;
           font-family:  PingFang SC ;
           margin: 15px 0;
       }
       .payment{
           color:#4E5AEE ;
           font-weight: 500;
           margin: 10px 0;
           font-size: 14px;
       }
       .title{
           font-size: 37px;
       }
       .describe{
           font-size: 18px;
           font-weight: 300;
           line-height: 30px;
       }
       .channels_con{
           width: 100%;
           height: 500px;
           margin-top: 80px;
           display: flex;
           justify-content: space-between;
           align-items: center;
           .left{
               width: 30%;
               height: 100%;
               p{
                   margin:0
                }
                .channels_payment{
                    font-size: 14px;
                    height: 40px;
                    line-height: 40px;
                    font-weight: 550;
                    position: relative;
                    &::before{
                        content: '';
                        display: block;
                        width: 2.5px;
                        height: 25px;
                        background:#4E5AEE ;
                        position: absolute;
                        left: -24px;
                        top: 5px;
                    }
                }
                .channels_desc{
                    width: 60%;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 24px;
                }
                .payments{
                    margin-top: 60px;
                    height: 70%;
                    display: flex;
                    flex-direction: column;
                    li{
                        height: 13%;
                        font-weight: 550;
                    }
                }
           }
           .right{
            width: 60%;
            height: 100%;
            position: relative;
            margin-left: 30px;
            .earth{
                margin-left: 60px;
            }
            .frame{
                position: absolute;
                left:-5%;
                top: 17%;
            }
        }
       }
   }
}