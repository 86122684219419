.terminals{
    background: #F7FAFC;
    padding: 6em 0;
    .desc{
        padding: 0 2em;
        p{
            margin:0.5em 0;
        }
        .title1{
            font-size: 1.2em;
            color: #4E5AEE;
        }
        .title2{
            font-size: 2.3em;
            line-height: 1.5em;
        }
        .title3{
            width: 80%;
            font-size: 1.2em;
            line-height: 2em;
            margin: 1em 0;
        }
    }
    .terminal{
        padding: 0 1em;
         display: flex;
         flex-wrap: wrap;
         position: relative;
         justify-content: flex-start;
        .phone{
            width: 40%;
            img{
                width: 100%;
                
            }
        }
        .watch{
            width: 45%;
            img{
                width: 100%;
            }
        }
        .order{
            width: 60%;
            position: absolute;
            right: 0;
            bottom: -1em;
            img{
                width: 100%;
            }
        }
    }
}