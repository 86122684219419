.header-w {
    width: 100%;

}

.header {
    width: 1100px;
    margin: 0 auto;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;

    .link {
        width: 450px;
        height: 65px;
        font-size: 14px;
        font-weight: 500;
        font-family: PingFang SC;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 0;

        li {
            display: flex;
            cursor: pointer;
            justify-content: center;
            align-items: center;
            width: 120px;
            font-size: 14px;

            &:hover {
                color: #4E5AEE;
            }

            button {
                width: 90px;
                height: 34px;
                background: #4E5AEE;
                color: #fff;
                border-radius: 6px;
                font-size: 14px;
            }
        }
    }
}