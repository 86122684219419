.navbar {
    width: 1100px;
    margin: 0 auto;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .link {
        width: 450px;
        height: 100%;
        font-size: 14px;
        font-weight: 500;
        font-family: PingFang SC;
        display: flex;
        position: relative;

        justify-content: flex-end;

        li {
            display: flex;
            cursor: pointer;
            justify-content: center;
            align-items: center;
            width: 120px;
            height: 100%;

            font-size: 14px;

            span {
                display: flex;
                font-size: 14px;
                width: 100%;
                height: 100%;
                align-items: center;
                justify-content: center;
            }

            button {
                width: 90px;
                height: 34px;
                background: #4E5AEE;
                color: #fff;
                border-radius: 6px;
                font-size: 14px;
            }
        }
    }

    li:hover {
        color: #4E5AEE;
    }
}

.prods:hover .sublink {
    display: block;
}

.sublink {
    width: 150px;
    display: none;
    position: absolute;
    bottom: -170px;
    z-index: 4;
    border: 1px solid rgba(255, 255, 255, 0.56);
    box-sizing: border-box;
    backdrop-filter: blur(7px);
    border-radius: 8px;
    background: linear-gradient(179.79deg, rgba(255, 255, 255, 0.5) 9.82%, #FFFFFF 99.81%), rgba(255, 255, 255, 0.4);
    color: #333;
    box-sizing: border-box;
    padding: 10px 20px;
}

.link-item {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    display: flex;

    &:hover {
        color: #4E5AEE;
    }

    .img-w {
        display: flex;
        align-items: center;
        margin-right: 20px;
    }
}