.footer{
    width: 100%;
    padding: 4em 2em;
    padding-bottom: 10em;
    position: relative;
    .linearbar{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 0.2em;
        background: linear-gradient(90deg, #01E5F9 0%, #4F5BEE 102.16%);
        transform: matrix(1, 0, 0, -1, 0, 0);
    }
    .logo{
        display: block;
        margin: 1em 0;
    }
    .link{
        display: flex;
        margin: 2em 0;
        ul{
            width: 50%;
            li{
                font-size: 1.3em;
                margin: 1.5em 0;
                font-weight: 550;
                a{
                    color: #3D4255;
                    font-size: 1em;
                    font-weight: 300;
                    &:hover{
                        color:#4F5BEE;
                    }
                }
            }
        }
    }
    .contact{
        border-bottom: 0.2em solid rgba(78, 90, 238, 0.3);
        margin-bottom: 2em ;
        .title{
            font-size: 1.3em;

        }
        .subtitle{
            font-size: 1.2em;
            font-weight: 300;
            margin: 1.5em 0;
            line-height: 1.8em;
        }
    }
    .copyright{
        font-size: 0.6em;
        margin: 1em 0;
        color:#3D4255;
        a{
            color:#3D4255
        }
    }
    .hy{
        margin: 1em 0;
        text-align: center;
    }
}