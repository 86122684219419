.terminals_wrap{
    width: 100%;
    background: #F7FAFC;
    .terminals_con{
        width: 1100px;
        margin: 0 auto;
        padding-top: 80px;
        height: 980px;
        position: relative;
        .desc{
            p{
                font-family: PingFang SC;
                margin: 22px 0;
            }
            .title1{
                font-size: 14px;
                color: #4E5AEE;
            }
            .title2{
                font-size: 37px;
                font-weight: 500;
                margin: 15px 0;
            }
            .title3{
                font-size: 14px;
                font-weight: 300;
            }
        }
        .phone{
            position: absolute;
            left: -30px;
            top: 270px;
        }
        .watch{
            position: absolute;
            left: 300px;
         
        }
        .order{
            position: absolute;
            left: 300px;
            bottom: 20px;
        }
        .payment{
            position: absolute;
            right: 40px;
            top: 85px;
           .con{
               width: 480px;
               height: 320px;
               position:relative;
                .bg{
                    position: absolute;
                    left: 0;
                }
                .logo{
                    position: absolute;
                    top: 45px;
                    left: 90px;
                }
                .member{
                    position: absolute;
                    right: 42px;
                    z-index: 1;
                    top: 38px;
                }
                .price{
                    position: absolute;
                    right: 40px;
                    top: 65px;
                }
                .card{
                    position: absolute;
                    left: 86px;
                    z-index: 3;
                    top: 150px;
                }
                .info{
                    position: absolute;
                    left: 86px;
                    z-index: 3;
                    top: 210px;
                }
                .button{
                    position: absolute;
                    left: 86px;
                    bottom: 20px;
                }
                .bottom{
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    
                }
                .pay_methods{
                    position: absolute;
                    display: flex;
                    color: #333;
                    width: 328px;
                    top: 100px;
                    left: 85px;
                    font-family:  PingFang SC;
                    font-size: 12px;
                    height: 34px;
                    border-bottom: 1px solid #eee;
                    &::before{
                        content: '';
                        width: 57px;
                        height: 1px;
                        background: #4E5AEE;
                        position: absolute;
                        top:33px;
                    }
                    li{
                        line-height: 34px;
                        display: flex;
                        justify-content:center;
                        margin-right: 22px;
                        transform: scale(0.85);
                    }  
                    .first{
                        color: #4E5AEE;
                    }
                   
                }
        
           }
        }
    }
}