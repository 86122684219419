.shop{
    width: 1100px;
    margin: 0 auto;
    padding-top: 150px;
    padding-bottom: 100px;
    height: 900px;
    display: flex;
    justify-content: space-between;

    .shop_desc{
        width: 35%;
        .title1{
            font-size: 14px;
            color: #4E5AEE;
            margin: 10px 0;
        }
        .title2{
            font-size: 37px;
            margin: 25px 0;
            line-height: 24px;
        }
        .title3{
            font-size: 18px;
            font-weight: 300;
            margin: 10px 0;
            line-height: 28px;
        }
    }
    .modules{
            font-size: 14px;
            position: relative;
            margin-top: 60px;
            margin-bottom: 10px;
            &::before{
            content: '';
            display: block;
            width: 2.5px;
            height: 25px;
            background:#4E5AEE ;
            position: absolute;
            left: -24px;
            top: -5px;
        }
    }
    .moduleName{
        margin-left: 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 250px;
        li{
           position: relative;
           font-size: 18px;
           font-weight: 300;
           margin: 25px 0;
            &::before{  
                content: '';
                width: 16px;
                height: 16px;
                font-size: 12px;
                left: -30px;
                top: 5px;
                display: flex;
                // color: #4E5AEE;
                justify-content: center;
                position: absolute;
                align-items: center;
                border-radius: 50%;
                background-image: url(../../assets/icon/Vector.png) ;
                background-repeat: no-repeat;
                background-position: center;
                background-color:  rgba(78, 90, 238, 0.2);
            }
        }
    }
    .btn_group{
        margin-top: 40px;
        .btn1{
            background: #4E5AEE;
            color: #fff;
            height: 34px;
            border-radius: 6px;
        }
        .btn2{
            color:#4E5AEE;
            margin-left: 20px;
        }
    }
    .topics{
        width: 60%;
        margin-top: -40px;
        margin-left: 50px;
      
        img{
            width: 100%;
        }
        
    }
}
