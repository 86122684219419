@charset "utf-8";
 /* 禁用iPhone中字号调整 */ 
html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
/* 解决IOS默认滑动很卡的情况  */ 
    -webkit-overflow-scrolling : touch;      
}
/* 移动端点击a链接出现蓝色背景问题解决 */
.mobile{
    body, div, ul, li, ol, h1, h2, h3, h4, h5, h6, input, textarea, select, p, dl, dt, dd, a, img, button, form, table, th, tr, td, tbody, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    a:link,a:active,a:visited,a:hover {
        background: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        -webkit-tap-highlight-color: transparent;
    }
    input[type="submit"], input[type="reset"], input[type="button"], input {
        resize: none;
        border: none;
    }
     /* 设置HTML5元素为块 */
    article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
        display: block;
    }
    
    /* 图片自适应 */
    img {
        width: 100%;
        height: auto;
        display: block;
        pointer-events: none;  /* 在安卓手机点击禁止图片预览 */
    }
    
    /* 初始化 */
    body, div, ul, li, ol, h1, h2, h3, h4, h5, h6, input, textarea, select, p, dl, dt, dd, a, img, button, form, table, th, tr, td, tbody, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
        margin: 0;
        padding: 0;
    }
    body {
        font: 12px/1.5 'Microsoft YaHei','宋体', Tahoma, Arial, sans-serif;
        color: #555;
        background-color: #F7F7F7;
    }
    em, i {
        font-style: normal;
    }
    ul,ol,li {
        list-style: none;
    }
    strong {
        font-weight: normal;
    }
    a {
        text-decoration: none;
        color: #969696;
        font-family: 'Microsoft YaHei', Tahoma, Arial, sans-serif;
    }
    a:hover {
        text-decoration: none;
    }
    h1, h2, h3, h4, h5, h6 {
        font-size: 100%;
        font-family: 'Microsoft YaHei';
    }
    img {
        border: none;
    }
    input {
        font-family: 'Microsoft YaHei';
    }
    .fl {
        float: left;
    }
    .fr {
        float: right;
    }
    .clearfix:after {
        content: "";
        display: block;
        clear: both;
    }
    .clearfix {
        zoom: 1;
    }
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */      
    image-rendering: -webkit-optimize-contrast; /*Webkit (non-standard naming) */ 
    image-rendering: crisp-edges; 
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}


 