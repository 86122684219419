.banner {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    position: relative;

    .banner_text {
        width: 440px;
        // border: 1px dashed #000;
        margin-top: 180px;

        p {
            font-family: PingFang SC;
        }

        .paysuite {
            background: url(../../assets/icon/paysuite.png) no-repeat;
            height: 56px;
        }

        .subtitle {
            font-size: 44px;
            margin: 30px 0;
        }

        .describe {
            width: 384px;
            font-size: 14px;
            line-height: 26px;
        }

        i {
            color: #4E5AEE;
            font-weight: 550;
        }

        .btn_group {
            width: 220px;
            margin: 60px 0;
            display: flex;
            justify-content: space-between;

            button {
                font-size: 14px;
            }

            .toBegin {
                background: #4E5AEE;
                color: #fff;
                height: 36px;
                border-radius: 6px;
            }

            .contact {
                color: #4E5AEE;
                font-size: 14px;
            }
        }
    }

    // .chart {
    //     float: right;
    //     height: 500px;
    //     margin-top: 150px;
    // }

    .phone {
        display: block;
        position: absolute;
        bottom: -20%;
        left: 34%;
        z-index: 2;
    }

    .chart {
        background: linear-gradient(179.74deg, rgba(255, 255, 255, 0) 63.8%, #FFFFFF 72.98%, #FFFFFF 99.77%), rgba(255, 255, 255, 0.4);
        border: 1px solid rgba(255, 255, 255, .7);
        position: absolute;
        width: 560px;
        height: 376px;
        right: 0px;
        top: 24%;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        backdrop-filter: blur(7px);
        box-shadow: 0px 20px 88px rgba(32, 36, 91, 0.2);
    }

    .Podcast {
        margin-right: 50px;

    }
}