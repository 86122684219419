.footer_wrap {
    padding-bottom: 50px;

    .linear_line {
        width: 100%;
        height: 3px;
        background: linear-gradient(90deg, #01E5F9 0%, #4F5BEE 102.16%);
        transform: matrix(1, 0, 0, -1, 0, 0);
    }

    .footer {
        width: 1100px;
        height: 250px;
        margin: 0 auto;
        margin-top: 80px;
        display: flex;
        justify-content: center;

        p {
            line-height: 24px;
        }

        .item {
            flex: 1;

            .title {
                font-weight: 500;
            }

            ul {
                li {
                    font-family: PingFang SC;
                    font-size: 14px;
                    margin-bottom: 18px;
                    color: #333;

                    span {
                        color: #3D4255;
                        font-weight: 300;
                        font-size: 13px;
                        cursor: pointer;

                        &:hover {
                            color: #4E5AEE;

                        }
                    }
                }

            }

            .desc {
                font-weight: 300;

                p {
                    font-size: 12px;
                    color: #3D4255;
                    font-family: PingFang SC;
                }
            }
        }
    }

    .footer_bot {
        width: 1100px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        a {
            color: #3D4255;
        }
    }
}